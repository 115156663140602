/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.btn:focus {
  box-shadow: none;
}

.react-bootstrap-table table {
  min-width: 100%;
}

.react-bootstrap-table table thead th {
  border-bottom-width: 1px;
}

.react-bootstrap-table table td.react-bootstrap-table-editing-cell input {
  padding-top: 0px;
  padding-bottom: 0px;
  border-width: 0px;
  height: fit-content;
}
